import React from 'react'
import aboutImg6 from '../../assets/images/banner-img2.png'


const Content = () => {
    return (
        <div className="upcoming-bootcamps-area ptb-70 bg-e0d4ff">
            <div className="container">
                <div className="upcoming-bootcamps-content bottom">
                    <h2 className="pb-50">Quelques conseils pour sauvegarder vos données</h2>
                    <p>Savoir où sont vos données est important, ne serait-ce que pour ne pas les perdre !<br></br>
                    <span className='bold color-seekreet'>Seekreet</span> vous propose avant toutes choses, d'évaluer la volumétrie de vos données, et en fonction de cela nous vous recommanderons le matériel adéquat.</p>
                </div>

                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content bottom">
                            <h3 className="h2-top">Le backup</h3>
                            <p>Le backup est <span className='bold'>l'opération de sauvegarde de vos données</span>. C'est une opération importante car elle doit vous permettre de :</p>
                            <ul className="features-list-custom">
                                <li><span><i className='bx bx-check'></i> Récupérer vos données en cas de problème d'accès aux données qui sont dans le Cloud ou vos ordinateurs.</span></li>
                                <li><span><i className='bx bx-check'></i> Récupérer d'anciennes versions de vos données si vous les avez effacées par erreur ou si elles sont corrompues voire chiffrées par un pirate malveillant qui vous demande de l'argent en échange de la clé de déchiffrement (ransomware).</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={aboutImg6} alt="about" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Content;