import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import scientist11 from '../../assets/images/scientist/scientist11.jpg'
import scientist9 from '../../assets/images/scientist/scientist9.jpg'
import scientist10 from '../../assets/images/scientist/scientist10.jpg'
import shape1 from '../../assets/images/shape/map-shape1.png'
import shape5 from '../../assets/images/shape/vector-shape5.png'

const Exemple = () => {
    return (
        <div className="scientist-area ptb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12 pb-30">
                        <div className="scientist-section-title">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Mise en application
                            </span>
                            <h3>Cas concret</h3>
                            <p className="bottom">Nous avons sécurisé les données d'un magasin : 4 vendeurs, 1 assistante administrative, 1 gestionnaire, 1 dirigeant.</p>
                            <h4>Constat :</h4>
                            <p>Les données sont d'origine et de confidentialité diverses.<br></br>
                            L'ensemble des documents usuels (fichiers clients, planning, plans) sont dans un compte Office Microsoft (One Drive) donc dans le Cloud.<br></br>
                            Les éléments plus confidentiels (comptabilité, administratif) sont dans le NAS et accessibles uniquement aux personnes concernées.<br></br>
                            Enfin les documents scannés vont directement dans un répertoire dédié du NAS.</p>
                            <p>Par ailleurs, des caméras de vidéo-surveillance ont été déployées. Elles sont gérées par le NAS et les flux vidéo y sont également sauvegardés.</p>

                        </div>
                    </div>
                    
                    <div className="col-lg-5 col-md-12 pt-30">
                        <div className="scientist-box-list">
                            <div className="row">
                                <div className="col-lg-6 col-sm-4 col-md-6 offset-lg-1">
                                    <div className="single-scientist-item">
                                        <img src={scientist10} alt="banner" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-4 col-md-6">
                                    <div className="single-scientist-item">
                                        <img src={scientist9} alt="banner" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-4 col-md-6 offset-lg-0 offset-md-3">
                                    <div className="single-scientist-item">
                                        <img src={scientist11} alt="banner" />
                                    </div>
                                </div>
                            </div>

                            <div className="map-shape1">
                                <img src={shape1} alt="banner" />
                            </div>
                            <div className="vector-shape5">
                                <img src={shape5} alt="banner" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Exemple