import React from 'react'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'

const BienChoisir = () => {
    return (
        <section className="overview-area ptb-70">
            <div className="container">
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={service2} alt="features" />
                        </div>
                    </div>

                    <div className="row align-items-center">
                    </div>
                    
                    <div className="overview-content">
                        <div className="content right-content">
                            <div>
                                <div className="about-content">
                                    <div className="content">
                                        <span className="sub-title">
                                            <img src={starIcon} alt="banner" /> 
                                            Bien choisir 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <h2>C'est bien joli tout ça, mais je prends quoi maintenant ?</h2>
                            <p>Vous devez arbitrer entre votre budget et la volumétrie de vos données :</p>
                            <ul className="features-list-custom">
                                <li><span><i className='bx bx-check'></i> Si vous n'avez pas beaucoup de données à sauvegarder ou que votre budget est limité, prenez un NAS à 2 disques durs en mode miroir (maintenant vous savez ce que ça veut dire). On parle de NAS à 2 baies et vous avez une tolérance de panne de 1 disque dur.</span></li>
                                <li><span><i className='bx bx-check'></i> Si vous avez une volumétrie importante, prenez un NAS avec au moins 4 baies et vous avez une tolérance de panne de 2 disques durs pour une volumétrie totale équivalent à 3 disques durs.</span></li>
                                <li><span><i className='bx bx-check'></i> Si la fiabilité et la disponibilité de vos données est cruciale et que vous avez le budget nécessaire, sachez qu'il existe aussi des NAS à 5, 6, 8 baies, voire jusqu'à 24 baies.</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BienChoisir