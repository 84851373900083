import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
//import shape from '../../assets/images/about/about-shape1.png'
import shape4 from '../../assets/images/shape/vector-shape4.png'
import nas from '../../assets/images/home/nas4.png'



const Nas = () => {
    return (
        <div className="scientist-area ptb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={nas} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="scientist-section-title">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Network Attached Storage
                            </span>
                        </div>
                        <div className='nas-liste'>
                            <h2>Qu’est ce qu’un NAS ?</h2>
                            <p>C'est tout simplement un <span className='bold'>périphérique de stockage</span> un peu plus intelligent que les disques durs ou les clés USB. C'est un dispositif <span className='bold'>comprenant plusieurs disques durs</span>, gérés par un environnement <span className='bold'>qui permet de configurer des sauvegardes de vos données</span> qui sont dans le Cloud, mais aussi évidemment sur vos ordinateurs.</p>
                            <h3>Pourquoi plusieurs disques durs ?</h3>
                            <p>Les disques durs étant des éléments mécaniques et malgré leur solidité, ils ne sont pas exempts de panne. Et qui dit panne dit plus de données. <br></br>Par ailleurs, certaines entreprises doivent pouvoir <span className='bold'>disposer d'un grand espace de stockage excédant la taille limite des disques durs les plus grands</span>.<br></br>
                            Selon vos besoins, <span className='bold'>nous adaptons la capacité de stockage de votre NAS, avec un ou plusieurs disques durs</span>. La capacité maximum actuelle d'un disque dur est de 18to.<br></br>
                            1to ou 1 téraoctet représente en volume de stockage environ :</p>
                            <ul>
                                <li><span><i class='bx bxs-info-square bx-flashing' ></i></span> 6,5 millions de pages PDF, office...</li>
                                <li><span><i class='bx bxs-info-square bx-flashing' ></i></span> ou 250 000 photo en 12MP</li>
                                <li><span><i class='bx bxs-info-square bx-flashing' ></i></span> ou 500 heures de vidéo HD...</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="vector-shape4">
                <img src={shape4} alt="banner" />
            </div>
        </div>
    )
}

export default Nas