import React from 'react'
//import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import caseStudy1 from '../../assets/images/case-study/case-study1.jpg'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};

const CaseStudy = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="case-study-area bg-e0d4ff">
            {display ? <OwlCarousel 
                className="case-study-slides owl-carousel owl-theme"
                {...options}
            >
                <div className="single-case-study-item ptb-70">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="case-study" /> 
                                        Option #1
                                    </span>
                                    <h2>Agréger les disques durs :</h2>
                                    <p>vous ne voyez qu'un seul disque dur dont la taille est la somme des tailles des disques durs installés dans le NAS</p>
                                    <ul>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i><span className='bold'>Avantage :</span> vous n'avez pas à vous préoccuper de la saturation de vos disques durs.</li>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i><span className='bold'>Inconvénient :</span> si un des disques tombe en panne, plus rien n'est accessible.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-image">
                                    <span className="d-block">
                                        <img src={caseStudy1} alt="case-study" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="single-case-study-item ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="case-study" /> 
                                        Option #2
                                    </span>
                                    <h2>Mettre les disques durs en mode miroir :</h2>
                                    <p>chaque disque dur est l'exacte copie du disque dur principal</p>
                                    <ul>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i><span className='bold'>Avantage :</span> Si l'un des disques durs tombe en panne, l'autre disque dur prend le relais et vous pouvez remplacer le disque dur défectueux sans avoir à tout éteindre. On parle de remplacement à chaud.</li>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i><span className='bold'>Inconvénient :</span> l'espace disponible est toujours le même quel que soit le nombre de disques durs puisqu'ils sont en mode miroir.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-image">
                                    <div className="d-block">
                                        <img src={caseStudy1} alt="case-study" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="single-case-study-item ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="case-study" /> 
                                        Option #3
                                    </span>
                                    <h2>Combiner les deux stratégies :</h2>
                                    <p>vous agrégez un nombre de disques durs et vous les mettez en miroir. Cela nécessite toutefois au minimum 4 disques durs.</p>
                                    <ul>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i><span className='bold'>Avantage :</span> le meilleur des 2 mondes.</li>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i><span className='bold'>Inconvénient :</span> Un peu cher (mais vos données le valent bien).</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-image">
                                    <div className="d-block">
                                        <img src={caseStudy1} alt="case-study" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel> : ''}
        </div>
    )
}

export default CaseStudy