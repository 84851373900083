import React from 'react'

const ContentTwo = () => {
    return (
        <div className="upcoming-bootcamps-area pt-70 pb-100">
            <div className="container">
                <div className="upcoming-bootcamps-content">
                    <h3>Choix du matériel :</h3>
                    <p>Nous avons préconisé et déployé la solution suivante : <span className='bold'>un NAS Synology DS920+ avec 4 disques durs de 4 To</span> chacun configurés en RAID 6. Cela veut dire que 7To d'espace disque sont disponible avec une tolérance de panne de 2 disques durs. Le RAID 6 apporte la meilleure protection des données au détriment d'une légère perte de vitesse d'accès aux disques durs.</p>
                    <p>Nous avons configuré un <span className='bold'>backup automatique de tous les documents se situant dans le Cloud</span>, puis mis en place un système d'historique des données. Ainsi si internet n'est plus accessible, il sera toujours possible de travailler avec les documents du Cloud puisqu'ils sont en local.</p>
                    <p>Le NAS est aussi <span className='bold'>accessible à distance</span> par la mise en place d'une connexion sécurisée (VPN) accessible uniquement par les identifiants adéquats.</p>
                    <h3 className="pt-50">Aïe, coupure d'électricité</h3>
                    <p>Si vous êtes dans un secteur sujet à des fluctuations électriques (ou pas), <span className='bold'>nous vous préconisons l'achat d'un onduleur</span> qui permettra au NAS de s'éteindre proprement, voire assurer une certaine autonomie proportionnelle à la taille de la batterie.</p>
                </div>
            </div>
        </div>
    )
}

export default ContentTwo;