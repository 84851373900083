import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import ModSauvDonnees from "../components/SauvegardeDeDonnee/ModSauvDonnees"
import Nas from "../components/SauvegardeDeDonnee/Nas"
import BienChoisir from "../components/SauvegardeDeDonnee/BienChoisir"
import ContentCarousel from "../components/SauvegardeDeDonnee/ContentCarousel"
import Content from "../components/SauvegardeDeDonnee/Content"
import Exemple from "../components/SauvegardeDeDonnee/Exemple"
import ContentTwo from "../components/SauvegardeDeDonnee/ContentTwo"
import NotreOffre from "../components/SauvegardeDeDonnee/NotreOffre"
import { Helmet } from "react-helmet"

const SauvegardeDeDonnee = () => {
  return (
    <Layout>
      <Helmet>
        <title>Seekreet | Sauvegarde de données</title>
        <meta name="description" content="Vos données ont de l’importance ? Selon vos besoins nous mettons en place une sauvegarde sécurisée. Connaissez vous le 3-2-1 de la sauvegarde, une solution qui vous garantie la ..." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Sauvegarde de donnée" 
          homePageText="Accueil" 
          homePageUrl="/" 
          parentActive="Service Informatique" 
          parentPageUrl="/service-informatique"
          activePageText="Sauvegarde de donnée" 
      />
      <ModSauvDonnees />
      <Nas />
      <ContentCarousel />
      <BienChoisir />
      <Content />
      <Exemple />
      <ContentTwo />
      <NotreOffre />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default SauvegardeDeDonnee