import React from 'react'
import {Link} from 'gatsby'
import course3 from '../../assets/images/courses/course3.jpg'
import course6 from '../../assets/images/courses/course6.jpg'
import course2 from '../../assets/images/courses/course2.jpg'
import shape from '../../assets/images/shape/vector-shape6.png'

const NotreOffre = () => {
    return (
        <section className="courses-area ptb-70 bg-fafafb">
            <div className="container">
                <div className="section-title">
                    <h2>Notre offre</h2>
                    <p><span className='color-seekreet bold'>Seekreet</span> vous propose une offre tout compris </p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-courses-box">
                            <div className="courses-image">
                                <div className="d-block image">
                                    <img src={course3} alt="features" />
                                </div>
                                <div className="price shadow free">1</div>
                            </div>
                            <div className="courses-content">
                                <h3>
                                    L’audit de vos données
                                </h3>              
                                <p>Analyse du réseau en place et préconisations pour votre architecture réseau</p>      
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-courses-box ">
                            <div className="courses-image">
                                <div className="d-block image">
                                    <img src={course6} alt="features" />
                                </div>
                                <div className="price shadow free">2</div>
                            </div>
                            <div className="courses-content">
                                <h3>
                                    La fourniture du NAS
                                </h3>
                                <p>Mise en route du matériel et sauvegarde de vos données</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="single-courses-box">
                            <div className="courses-image">
                                <div className="d-block image">
                                    <img src={course2} alt="features" />
                                </div>
                                <div className="price shadow free">3</div>
                            </div>
                            <div className="courses-content">
                                <h3>
                                    Une assistance de proximité    
                                </h3>
                                <p>Nous privilégions la communication à la mise en place d'un système de ticket sur le web</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="courses-info">
                            <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Besoin d'un conseil <span></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="vector-shape6">
                <img src={shape} alt="features" />
            </div>
        </section>
    )
}

export default NotreOffre