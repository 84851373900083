import React from 'react'
import { Link } from 'gatsby'
import cloud from '../../assets/images/services/cloud.png'
import files from '../../assets/images/services/files.png'
import usb from '../../assets/images/services/usb.png'
import shape1 from '../../assets/images/shape/map-shape1.png'
import shape5 from '../../assets/images/shape/vector-shape5.png'
import starIcon from '../../assets/images/star-icon.png'

const ModSauvDonnees = () => {
    return (
        <div className="about-area ptb-70 bg-e0d4ff">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="scientist-box-list">
                            <div className="row">
                                <div className="col-lg-6 col-sm-4 col-md-6 offset-lg-1">
                                    <div className="single-scientist-item">
                                        <img src={cloud} alt="banner" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-4 col-md-6 offset-lg-0 offset-md-3">
                                    <div className="single-scientist-item">
                                        <img src={files} alt="banner" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-4 col-md-6 offset-lg-0 offset-md-3">
                                    <div className="single-scientist-item">
                                        <img src={usb} alt="banner" />
                                    </div>
                                </div>
                            </div>

                            <div className="map-shape1">
                                <img src={shape1} alt="banner" />
                            </div>
                            <div className="vector-shape5">
                                <img src={shape5} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Sauvegarde de donnée
                                </span>
                                <h2>Sécurisez la sauvegarde de vos données</h2>
                                <p>L'augmentation de la <span className='bold'>cybercriminalité</span>, les évènements comme l'incendie chez OVH, ainsi que la guerre technologique actuelle fragilisent la stabilité de nos systèmes.<br></br>
                                L'<span className='bold'>ANSSI</span> (Agence Nationale de la Sécurité des Systèmes d'Information) a enregistré 1082 intrusions avérées dans des systèmes d'information en 2021, pour 786 en 2020. Aujourd’hui, l'ANSSI recommande fortement de <span className='bold'>sauvegarder hors-ligne les données et les applications critiques</span>.</p>
                                <p>Lorsque l'on est une TPE ou une PME, il n'existe pas forcément dans l'entreprise les compétences nécessaires à la mise en place d'une telle solution de sauvegarde hors-ligne.<br></br>
                                <span className='bold color-seekreet'>Seekreet</span> vous explique comment faire pour remédier à cela en mettant en place une <span className='bold'>sauvegarde locale</span>.</p>
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Besoin d'un conseil <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModSauvDonnees